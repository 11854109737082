import React from "react";
import AboutDetail1 from "../../assets/images/resources/about detail-1.jpg";
import AboutThreeClient from "../../assets/images/resources/about-three-client-img.jpg";
import AboutThreeClientSignature from "../../assets/images/shapes/about-three-client-signature.png";
import WhyChooseFiveShape from "../../assets/images/shapes/why-choose-five-shape-1.png";
import WhyChooseFiveShape2 from "../../assets/images/shapes/why-choose-five-shape-2.png";
import AboutBanner from "../../assets/images/backgrounds/about-banner.jpg";
import WhyChooseFiveShape3 from "../../assets/images/shapes/why-choose-five-shape-3.png";
import SiteFooterFour from "../../assets/images/shapes/site-footer-four-shape-1.png";
import Feature1Shape1 from "../../assets/images/shapes/feature-one-shape-1.png";
import Feature1HoverShape2 from "../../assets/images/shapes/feature-one-hover-shape-2.png";
import Feature1HoverShape1 from "../../assets/images/shapes/feature-one-hover-shape-1.png";
import CTAThreeBgShape from "../../assets/images/shapes/cta-three-bg-shape.png";
import SiteFooterFourShape1 from "../../assets/images/shapes/site-footer-four-shape-1.png";
import MainliderFourShape1 from "../../assets/images/shapes/main-slider-four-shape-1.png";
import WhyChooseThreeShape1 from "../../assets/images/shapes/why-choose-three-shape-1.png";
import WhyChooseThreeShape2 from "../../assets/images/shapes/why-choose-three-shape-2.png";
import WhyChooseThreeShape3 from "../../assets/images/shapes/why-choose-three-shape-3.png";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import TalentSyncLogo from "../../assets/images/talentSync_Logo.png";

const Abouts = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box
      sx={{ width: 250, backgroundColor: "#040b1e", color: "white" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <div
        style={{
          display: "flex ",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "fit-content",
        }}
      >
        <img src={TalentSyncLogo} style={{ width: "55%" }} alt="logo"></img>
        <span
          className="mobile-nav__close mobile-nav__toggler"
          style={{ marginTop: "5%" }}
        >
          <i className="fa fa-times" onClick={toggleDrawer(false)}></i>
        </span>
      </div>
      <List>
        {/* Navigation Items */}
        <ListItem button component="a" href="/">
          <ListItemText
            primary="Home"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/about">
          <ListItemText
            primary="About"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/pricing">
          <ListItemText
            primary="Pricing"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/how-it-works">
          <ListItemText
            primary="How It Works"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/contact">
          <ListItemText
            primary="Contact"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
      </List>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
          marginTop: "8%",
        }}
      >
        <a href="#" className="fab fa-twitter" style={{ color: "white" }}></a>
        <a
          href="#"
          className="fab fa-facebook-square"
          style={{ color: "white" }}
        ></a>
        <a
          href="#"
          className="fab fa-pinterest-p"
          style={{ color: "white" }}
        ></a>
        <a href="#" className="fab fa-instagram" style={{ color: "white" }}></a>
      </div>
    </Box>
  );
  return (
    <div>
      <div className="page-wrapper">
        <div className="page-wrapper">
          <header className="main-header-four">
            <div className="main-header-four__top">
              <div className="container">
                <div className="main-header-four__top-inner">
                  <div className="main-header-four__top-left">
                    <div className="main-header-four__logo">
                      {/* <!-- <a href="/"><img src="assets/images/resources/talentsync-logo.png" alt=""></a> --> */}
                      <a href="/">
                        <img
                          src={TalentSyncLogo}
                          style={{ width: "20%" }}
                        ></img>
                      </a>
                    </div>
                    {/* <!-- <div className="main-header-four__search-box">
                                <form action="#" className="main-header-four__search-form">
                                    <input type="search" placeholder="Search....">
                                    <button type="submit"><i className="icon-search"></i></button>
                                </form>
                            </div> --> */}
                  </div>
                  <ul className="list-unstyled main-header-four__contact-list">
                    <li>
                      <div className="text">
                        <span>email</span>
                        <p>
                          <a href="mailto:sonchoy@gmail.com">
                            support@mail.talentsync.agency
                          </a>
                        </p>
                      </div>
                    </li>
                    {/* <!-- <li>
                                <div className="text">
                                    <span>PHONE</span>
                                    <p><a href="tel:18000000000">1800 000 0000 </a></p>
                                </div>
                            </li> --> */}
                  </ul>
                </div>
              </div>
            </div>
            <nav className="main-menu main-menu-four">
              <div className="main-menu-four__wrapper">
                <div className="container">
                  <div className="main-menu-four__wrapper-inner">
                    <div className="main-menu-four__left">
                      <div className="main-menu-four__main-menu-box">
                        <a className="mobile-nav__toggler">
                          <i
                            className="fa fa-bars"
                            onClick={toggleDrawer(true)}
                          ></i>
                        </a>
                        <Drawer
                          sx={{
                            "& .MuiDrawer-paper": {
                              backgroundColor: "#040b1e",
                              px: "15px",
                              py: "30px",
                            },
                          }}
                          open={open}
                          onClose={toggleDrawer(false)}
                        >
                          {DrawerList}
                        </Drawer>
                        <ul className="main-menu__list">
                          <li className="dropdown">
                            <a href="/">Home </a>
                          </li>
                          <li>
                            <a href="/about">About</a>
                          </li>

                          <li>
                            <a href="/pricing">Pricing</a>
                          </li>

                          <li>
                            <a href="/how-it-works">How It Works</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="main-menu-four__right">
                      <div className="main-menu-four__cart">
                        <a href="#">
                          <span className="icon-cart"></span>
                        </a>
                      </div>
                      {/* <div className="main-menu-four__btn-box">
                      <a
                        href="/contact"
                        className="main-menu-four__btn thm-btn"
                      >
                        GET A QUOTE
                      </a>
                    </div> */}
                      <div
                        className="main-menu-four__btn-box"
                        style={{ marginLeft: "10px" }}
                      >
                        <a
                          href="/login"
                          className="main-menu-four__btn thm-btn"
                        >
                          Log In
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>

          <div className="stricky-header stricked-menu main-menu main-menu-four">
            <div className="sticky-header__content"></div>
          </div>

          {/* <!--Page Header Start--> */}
          <section className="page-header">
            <div
              className="page-header__bg"
              style={{
                backgroundImage: `url(${AboutBanner})`,
              }}
            ></div>
            <div className="container">
              <div className="page-header__inner">
                <h2>About Us</h2>
                <div className="thm-breadcrumb__box">
                  <ul className="thm-breadcrumb list-unstyled">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <span>-</span>
                    </li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/* <!--Page Header End--> */}

          {/* <!--Feature One Start --> */}
          <section className="feature-one">
            <div className="container">
              <div className="row">
                {/* <!--Feature One Single Start--> */}
                <div
                  className="col-xl-4 col-lg-6 wow fadeInLeft"
                  data-wow-delay="100ms"
                >
                  <div className="feature-one__single">
                    <div
                      className="feature-one__shape-1"
                      style={{
                        backgroundImage: `url(${Feature1Shape1})`,
                      }}
                    ></div>
                    <div
                      className="feature-one__hover-shape-1"
                      style={{
                        backgroundImage: `url(${Feature1HoverShape2})`,
                      }}
                    ></div>
                    <div className="feature-one__icon">
                      <span className="icon-protection"></span>
                    </div>
                    <div className="feature-one__content">
                      <p className="feature-one__sub-title">
                        TalentSync Agency
                      </p>
                      <h3 className="feature-one__title">
                        <a href="/about">First & Reliable</a>
                      </h3>
                      {/* <!-- <a href="/about" className="feature-one__read-more">READ MORE<span
                                        className="icon-next"></span></a> --> */}
                    </div>
                  </div>
                </div>
                {/* <!--Feature One Single End-->
                    <!--Feature One Single Start--> */}
                <div
                  className="col-xl-4 col-lg-6 wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div className="feature-one__single">
                    <div
                      className="feature-one__shape-1"
                      style={{
                        backgroundImage: `url(${Feature1Shape1})`,
                      }}
                    ></div>
                    <div
                      className="feature-one__hover-shape-1"
                      style={{
                        backgroundImage: `url(${Feature1HoverShape2})`,
                      }}
                    ></div>
                    <div className="feature-one__icon">
                      <span className="icon-investment"></span>
                    </div>
                    <div className="feature-one__content">
                      <p className="feature-one__sub-title">
                        TalentSync Agency
                      </p>
                      <h3 className="feature-one__title">
                        <a href="/about">Invest Your Money</a>
                      </h3>
                      {/* <!-- <a href="/about" className="feature-one__read-more">READ MORE<span
                                        className="icon-next"></span></a> --> */}
                    </div>
                  </div>
                </div>
                {/* <!--Feature One Single End-->
                    <!--Feature One Single Start--> */}
                <div
                  className="col-xl-4 col-lg-6 wow fadeInRight"
                  data-wow-delay="100ms"
                >
                  <div className="feature-one__single">
                    <div
                      className="feature-one__shape-1"
                      style={{
                        backgroundImage: `url(${Feature1Shape1})`,
                      }}
                    ></div>
                    <div
                      className="feature-one__hover-shape-1"
                      style={{
                        backgroundImage: `url(${Feature1HoverShape2})`,
                      }}
                    ></div>
                    <div className="feature-one__icon">
                      <span className="icon-risk-management"></span>
                    </div>
                    <div className="feature-one__content">
                      <p className="feature-one__sub-title">
                        TalentSync Agency
                      </p>
                      <h3 className="feature-one__title">
                        <a href="/about">Get Your Job</a>
                      </h3>
                      {/* <!-- <a href="/about" className="feature-one__read-more">READ MORE<span
                                        className="icon-next"></span></a> --> */}
                    </div>
                  </div>
                </div>
                {/* <!--Feature One Single End--> */}
              </div>
            </div>
          </section>
          {/* <!--Feature One End --> */}

          {/* <!--About Three Start --> */}
          <section className="about-three about-six">
            <div className="container">
              <div className="row">
                <div className="col-xl-5">
                  <div className="about-three__left">
                    <div>
                      <img
                        src={AboutDetail1}
                        style={{ maxWidth: "550px" }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-7">
                  <div className="about-three__right">
                    <div className="section-title text-left">
                      <div className="section-title__tagline-box">
                        <p className="section-title__tagline">
                          KNOW ABOUT TALENTSYNC
                        </p>
                      </div>
                      <h2 className="section-title__title">
                        Get Noticed by Top Employers: TalentSync
                      </h2>
                    </div>
                    <p className="about-three__text">
                      TalentSync.agency is dedicated to helping job seekers like
                      you stand out in a competitive job market. We understand
                      the frustration of applying online and getting lost in the
                      crowd. That’s why we take a proactive approach, directly
                      connecting you with hiring managers at your target
                      companies. Our team of experienced recruiters will craft
                      personalized email campaigns to showcase your skills and
                      experience, ensuring you get noticed.
                    </p>

                    <div className="about-three__btn-and-client">
                      <div className="about-three__btn-box">
                        <a href="/about" className="about-three__btn thm-btn">
                          Discover More
                        </a>
                      </div>
                      <div className="about-three__client-img">
                        <img src={AboutThreeClient} alt="" />
                        <div className="about-three__client-signature">
                          <img src={AboutThreeClientSignature} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!--About Three End --> */}

          {/* <!--Why Choose Three Start--> */}
          <section className="why-choose-three why-choose-five">
            <div className="why-choose-three__shape-1">
              <img src={WhyChooseFiveShape} alt="" />
            </div>
            <div className="why-choose-three__shape-2 img-bounce">
              <img src={WhyChooseFiveShape2} alt="" />
            </div>
            <div className="why-choose-three__shape-3 float-bob-x">
              <img src={WhyChooseFiveShape3} alt="" />
            </div>
            <div className="container">
              <div className="section-title text-center">
                <div className="section-title__tagline-box">
                  <p className="section-title__tagline">Who We Are</p>
                </div>
                <h2 className="section-title__title">
                  Talentsync is a recruitment company specialized in searching
                  for job opportunities for our customer.
                </h2>
              </div>
              <div className="row">
                {/* <!--Why Choose Three Single Start--> */}
                <div
                  className="col-xl-6 col-lg-6 wow fadeInLeft"
                  data-wow-delay="100ms"
                >
                  <div className="why-choose-three__single">
                    <div className="why-choose-three__icon">
                      <span className="icon-bar-chart"></span>
                    </div>
                    <div className="why-choose-three__content">
                      <h3>
                        <a href="/about">Mission</a>
                      </h3>
                      <p>
                        To find a job for all individuals who have trusted us to
                        assist them on their career journey.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <!--Why Choose Three Single End-->
                    <!--Why Choose Three Single Start--> */}
                <div
                  className="col-xl-6 col-lg-6 wow fadeInRight"
                  data-wow-delay="300ms"
                >
                  <div className="why-choose-three__single">
                    <div className="why-choose-three__icon">
                      <span className=" icon-protection"></span>
                    </div>
                    <div className="why-choose-three__content">
                      <h3>
                        <a href="/about">Vision</a>
                      </h3>
                      <p>
                        To be a solution for any job seeker and a part of your
                        success story.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <!--Why Choose Three Single End-->
                    <!--Why Choose Three Single Start-->
                    <!-- <div className="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-delay="600ms">
                        <div className="why-choose-three__single">
                            <div className="why-choose-three__icon">
                                <span className="icon-bar-chart"></span>
                            </div>
                            <div className="why-choose-three__content">
                                <h3><a href="/about">Fast Processing System</a></h3>
                                <p>Through a unique coN construction and design dis
                                    nes expertise Concor and delivers</p>
                            </div>
                        </div>
                    </div> --> */}
                {/* <!--Why Choose Three Single End-->
                    <!--Why Choose Three Single Start--> */}
                {/* <!-- <div className="col-xl-6 col-lg-6 wow fadeInRight" data-wow-delay="900ms">
                        <div className="why-choose-three__single">
                            <div className="why-choose-three__icon">
                                <span className="icon-save-money-1"></span>
                            </div>
                            <div className="why-choose-three__content">
                                <h3><a href="/about">Deposite Money</a></h3>
                                <p>Through a unique coN construction and design dis
                                    nes expertise Concor and delivers</p>
                            </div>
                        </div>
                    </div> --> */}
                {/* <!--Why Choose Three Single End--> */}
              </div>
            </div>
          </section>
          {/* <!--Why Choose Three End--> */}

          {/* <!--CTA One Start --> */}
          <section className="cta-one cta-three">
            <div className="container">
              <div className="cta-one__inner">
                <div className="cta-one__bg" style={{ backgroundImage: `url('/static/media/cta-three-bg-shape.e9c0161ef88e1fcbc1f1.png')` }}></div>
                <div className="cta-one__title-box">
                  <h3>Are intrested in <span>Free</span> Service.</h3>
                  <p>We are here to help you. Currently we are in a testing phase if you are intrested to work together drop us a mail <br /> Lets Grow Together.</p>
                </div>
                <div className="cta-one__btn-box">
                  <a href="/contact" className="cta-one__btn thm-btn">Drop US A Mail</a>
                </div>
              </div>
            </div>
          </section>
          {/* <!--CTA One End --> */}

          {/* <!--Site Footer Start--> */}
          
        
        <footer className="site-footer site-footer-three site-footer-four">
          <div className="site-footer__shape-1 float-bob-y">
            <img src={SiteFooterFourShape1} alt="" />
          </div>
          <div className="site-footer__top">
            <div className="container">
              <div className="site-footer__top-inner">
                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                    data-wow-delay="100ms"
                  >
                    <div className="footer-widget__column footer-widget__about">
                      <div className="footer-widget__logo">
                        {/* <!-- <a href="/"><img src="assets/images/resources/footer-logo-4.png"
                                                alt=""></a> --> */}
                        <a href="/">
                          <h2 style={{ color: "white" }}>Talentsync</h2>
                        </a>
                      </div>
                      <p className="footer-widget__about-text">
                        Get Noticed by Employers: TalentSync Finds You the
                        Perfect Job
                      </p>
                      <div className="footer-widget__emergency-call">
                        <a href="mailto:support@mail.talentsync.com">
                         support@mail.talentsync.com
                        </a>
                      </div>
                      {/*
                      <div className="footer-widget__social">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                        <a href="#">
                          <span className="icon-instagram-1"></span>
                        </a>
                        <a href="#">
                          <span className="icon-tik-tok"></span>
                        </a>
                        <a href="#">
                          <span className="icon-youtube"></span>
                        </a>
                      </div>
                      */}
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                    data-wow-delay="200ms"
                  >
                    <div className="footer-widget__column footer-widget__navigation">
                      <div className="footer-widget__title-box">
                        <h3 className="footer-widget__title">Navigation</h3>
                      </div>
                      <ul className="footer-widget__navigation-list list-unstyled">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/about">About Us</a>
                        </li>
                        <li>
                          <a href="/how-it-works">How It Works</a>
                        </li>
                        <li>
                          <a href="/pricing">Pricing</a>
                        </li>
                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="site-footer__bottom-inner">
                <p className="site-footer__bottom-text">
                  <a>Copyright © 2024 Talentsync.Agency.</a> All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </footer>


        </div>
      </div>
    </div>
  );
};

export default Abouts;
