import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode to parse the JWT token
import SnackbarComponent from "../../components/Snackbar/Snackbar";
import axios from "axios";
import { backendUrl } from "../../constants/config";

const Login = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleLoginSuccess = async (response) => {
    const decoded = jwtDecode(response.credential);
    const userName = decoded.name;
    const userEmail = decoded.email;

    try {
      const resp = await axios.post(`${backendUrl}/user/loginUser`, {
        name: userName,
        email: userEmail,
      });

      localStorage.setItem("TalentSyncUser", JSON.stringify(resp.data.user));
      setSuccess("Successfully logged in!");
      if (resp.data.message === "User already exists.") {
        if (resp.data.user.userType === "admin") {
          navigate("/admin-dashboard/home");
        } else {
          if (resp.data.user.subscriptionBought) {
            navigate("/dashboard/home");
          } else {
            navigate("/payment");
          }
        }
      } else {
        navigate("/payment");
        // navigate("/profile-form");
      }
    } catch (err) {
      console.log(err);
      setErr("Error Logging in.");
    }
  };

  const handleLoginFailure = (error) => {
    console.log("Login Failed:", error);
    setErr("Failed to log in. Please try again.");
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ height: "500px", width: "500px" }}
    >
      <SnackbarComponent
        err={err}
        success={success}
        setErr={setErr}
        setSuccess={setSuccess}
      />
      <Row>
        <Col xs="12">
          <Card className="shadow-sm p-3 mb-5 bg-white rounded">
            <CardBody className="text-center">
              <h2 className="mb-4">Sign in with Google</h2>
              <p>Access your profile securely by signing in with Google</p>
              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
                render={(renderProps) => (
                  <Button
                    color="primary"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <i className="fa fa-google mr-2"></i> Sign in with Google
                  </Button>
                )}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
